<template>
  <div class="container">
    <div class="date-wrapper">
      <h3>Today's Date is...</h3>
      <h1>{{ formattedCurrentDate }}</h1>
      <p class="country-text">{{ country }}</p> <!-- Display country here -->
    </div>

    <!-- Tabs for switching between facts, birthdays, and deaths -->
    <div class="tabs">
      <button :class="{ active: selectedTab === 'facts' }" @click="selectedTab = 'facts'">On This Day</button>
      <button :class="{ active: selectedTab === 'birthdays' }" @click="selectedTab = 'birthdays'">Celebrity Birthdays</button>
      <button :class="{ active: selectedTab === 'deaths' }" @click="selectedTab = 'deaths'">Celebrity Deaths</button>
    </div>

    <!-- Historical Events -->
    <div v-if="selectedTab === 'facts'" class="fact-grid">
      <div v-for="event in sortedEvents" :key="event.year" class="fact-card">
        <h3>{{ event.year }}</h3>
        <p>{{ event.description }}</p>
      </div>
    </div>

    <!-- Celebrity Birthdays -->
    <div v-if="selectedTab === 'birthdays'" class="events-grid">
      <a v-for="birthday in sortedBirthdays" :key="birthday.name" :href="birthday.link" target="_blank" class="event-card-link">
        <div class="event-card">
          <img :src="birthday.image" alt="Celebrity Image" class="event-image" v-if="birthday.image" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW21teG4LWZDaAC-26yC4qfFpzZMFDSQ4d8w&s.jpg" alt="Placeholder" class="event-image" v-else />
          <h3 class="birthday-date">{{ birthday.age }} years old</h3>
          <p class="celeb-name">{{ extractName(birthday.description) }}</p>
          <p class="celeb-description">{{ extractDescription(birthday.description) }}</p>
        </div>
      </a>
    </div>

    <!-- Celebrity Deaths -->
    <div v-if="selectedTab === 'deaths'" class="events-grid">
      <a v-for="death in sortedDeaths" :key="death.name" :href="death.link" target="_blank" class="event-card-link">
        <div class="death-event-card">
          <img :src="death.image" alt="Celebrity Image" class="death-event-image" v-if="death.image" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW21teG4LWZDaAC-26yC4qfFpzZMFDSQ4d8w&s.jpg" alt="Placeholder" class="death-event-image" v-else />
          <h3 class="death-date">Died in {{ death.year }}</h3>
          <p class="celeb-name">{{ extractName(death.description) }}</p>
          <p class="death-date">{{ death.age }} years old</p>
          <p class="celeb-description">{{ extractDescription(death.description) }}</p>
        </div>
      </a>
    </div>

    <!-- Scroll Up Button -->
    <button v-if="showScrollUpButton" @click="scrollToTop" class="scroll-up-button">↑</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate: '',
      formattedCurrentDate: this.formatDate(new Date()), // Call the format function
      events: [],
      birthdays: [],
      deaths: [], // New deaths data
      selectedTab: 'facts',
      showScrollUpButton: false, // Show/hide scroll up button
    };
  },
  methods: {
    fetchDate() {
      const now = new Date();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      return `${month}-${day}`;
    },

    extractName(description) {
      return description.split(',')[0];
    },

    extractDescription(description) {
      return description.split(',').slice(1).join(',').trim();
    },

    formatDate(date) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-GB', options);
      const day = date.getDate();
      const suffix = this.getOrdinalSuffix(day);
      return formattedDate.replace(day, `${day}${suffix}`);
    },

    getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    },

       fetchTimezoneCountry() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const country = this.getCountryFromTimezone(timezone);

      if (country) {
        this.country = country;
      } else {
        console.error("Could not determine the country from the timezone.");
      }
    },

    getCountryFromTimezone(timezone) {
  const timezoneMap = {
    // North America
    "America/New_York": "United States",
    "America/Chicago": "United States",
    "America/Denver": "United States",
    "America/Los_Angeles": "United States",
    "America/Toronto": "Canada",
    "America/Vancouver": "Canada",
    "America/Mexico_City": "Mexico",
    "America/Argentina/Buenos_Aires": "Argentina",
    "America/Sao_Paulo": "Brazil",

    // Europe
    "Europe/London": "United Kingdom",
    "Europe/Paris": "France",
    "Europe/Berlin": "Germany",
    "Europe/Madrid": "Spain",
    "Europe/Rome": "Italy",
    "Europe/Amsterdam": "Netherlands",
    "Europe/Brussels": "Belgium",
    "Europe/Vienna": "Austria",
    "Europe/Zurich": "Switzerland",
    "Europe/Warsaw": "Poland",
    "Europe/Prague": "Czech Republic",
    "Europe/Athens": "Greece",
    "Europe/Istanbul": "Turkey",
    "Europe/Moscow": "Russia",

    // Asia
    "Asia/Tokyo": "Japan",
    "Asia/Seoul": "South Korea",
    "Asia/Shanghai": "China",
    "Asia/Hong_Kong": "Hong Kong",
    "Asia/Kolkata": "India",
    "Asia/Bangkok": "Thailand",
    "Asia/Singapore": "Singapore",
    "Asia/Kuala_Lumpur": "Malaysia",
    "Asia/Jakarta": "Indonesia",
    "Asia/Manila": "Philippines",
    "Asia/Riyadh": "Saudi Arabia",
    "Asia/Tehran": "Iran",
    "Asia/Baghdad": "Iraq",
    "Asia/Beirut": "Lebanon",
    "Asia/Dhaka": "Bangladesh",

    // Australia & Oceania
    "Australia/Sydney": "Australia",
    "Australia/Melbourne": "Australia",
    "Pacific/Auckland": "New Zealand",
    "Pacific/Fiji": "Fiji",
    "Pacific/Tongatapu": "Tonga",

    // Africa
    "Africa/Johannesburg": "South Africa",
    "Africa/Cairo": "Egypt",
    "Africa/Nairobi": "Kenya",
    "Africa/Lagos": "Nigeria",
    "Africa/Casablanca": "Morocco",
    "Africa/Algiers": "Algeria",
    "Africa/Addis_Ababa": "Ethiopia",
    "Africa/Dakar": "Senegal",
    "Africa/Kampala": "Uganda",

    // Middle East
    "Asia/Jerusalem": "Israel",
    "Asia/Amman": "Jordan",
    "Asia/Dubai": "United Arab Emirates",
    "Asia/Kuwait": "Kuwait",
    "Asia/Muscat": "Oman",

    // South America
    "America/Santiago": "Chile",
    "America/Lima": "Peru",
    "America/Bogota": "Colombia",
    "America/Caracas": "Venezuela",
    "America/La_Paz": "Bolivia",
    "America/Asuncion": "Paraguay",
    "America/Montevideo": "Uruguay",

    // Central America
    "America/Panama": "Panama",
    "America/Guatemala": "Guatemala",
    "America/Managua": "Nicaragua",

    // Caribbean
    "America/Havana": "Cuba",
    "America/Santo_Domingo": "Dominican Republic",
    "America/Port_of_Spain": "Trinidad and Tobago",
    "America/Jamaica": "Jamaica",
    "America/Puerto_Rico": "Puerto Rico",

    // Arctic and Antarctica
    "Antarctica/McMurdo": "Antarctica",
    "Antarctica/Rothera": "Antarctica",
    "Arctic/Longyearbyen": "Svalbard",

    // Pacific Islands
    "Pacific/Honolulu": "Hawaii (United States)",
    "Pacific/Tahiti": "French Polynesia",
    "Pacific/Guam": "Guam",
    "Pacific/Samoa": "Samoa",
    "Pacific/Pago_Pago": "American Samoa",

    // Other notable regions
    "Atlantic/Azores": "Azores (Portugal)",
    "Atlantic/Bermuda": "Bermuda",
    "Atlantic/Reykjavik": "Iceland",
    "Atlantic/Canary": "Canary Islands (Spain)",

    // Default fallback
    "UTC": "Coordinated Universal Time (UTC)",
  };

  return timezoneMap[timezone] || " "; // Return country if found, or fallback
},

async fetchWikiInfo() {
  try {
    const response = await fetch(`/wikiinfo.json`);
    const data = await response.json();

    const today = this.fetchDate();
    const nonPaddedDate = today.replace(/^0/, '').replace(/-0/, '-');

    this.events = data.filter(item => item.type === 'fact' && (item.date === today || item.date === nonPaddedDate));
    this.birthdays = data.filter(item => item.type === 'birthday' && (item.date === today || item.date === nonPaddedDate));
    this.deaths = data.filter(item => item.type === 'death' && (item.date === today || item.date === nonPaddedDate));

    // Sort function for AD and BC years
    const sortByYear = (a, b) => {
      const yearA = a.year.includes('BC') ? -parseInt(a.year) : parseInt(a.year);
      const yearB = b.year.includes('BC') ? -parseInt(b.year) : parseInt(b.year);
      return yearB - yearA; // Descending order
    };

    // Apply the sorting function
    this.events.sort(sortByYear);
    this.birthdays.sort(sortByYear);
    this.deaths.sort(sortByYear);

  } catch (error) {
    console.error("Error fetching Wikipedia info:", error);
  }
},

    calculateAge(birthdate) {
      if (!birthdate) return null; // Handle cases where the birthdate is not available

      const birthDate = new Date(birthdate);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      
      // Adjust age if the birth date has not occurred yet this year
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      return age;
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    checkScrollPosition() {
      this.showScrollUpButton = window.scrollY > 200;
    }
  },

  computed: {
    sortedEvents() {
      return this.events;
    },
    sortedBirthdays() {
      return this.birthdays
        .map(birthday => {
          const age = this.calculateAge(birthday.year);
          return { ...birthday, age };
        })
        .sort((a, b) => a.age - b.age);
    },
    sortedDeaths() {
  return this.deaths
    .map(death => {
      // Extract birth year from the description
      const birthYearMatch = death.description.match(/\(b\.\s*(\d{4})\)/);
      const birthYear = birthYearMatch ? parseInt(birthYearMatch[1]) : null; // Parse the year

      // Calculate age using the death year and birth year
      const age = birthYear ? (parseInt(death.year) - birthYear) : null;

      return { ...death, age };
    })
    .sort((a, b) => parseInt(b.year) - parseInt(a.year));
}

  },

  mounted() {
    this.fetchDate();
    this.fetchWikiInfo();
    this.fetchTimezoneCountry(); // Call the method to determine the country based on the timezone

    window.addEventListener('scroll', this.checkScrollPosition);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  }
};
</script>

<style>
/* Global styles for html and body */
html, body {
  background-color: black !important;
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Scroll Up Button Styles */
.scroll-up-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.death-date, .birthday-date {
  font-weight: bold; /* Correct way to make text bold */
  color: white !important;
  margin-top: -2px;
  margin-bottom: 10px;
}

.country-text {
  color: #d1d5db; /* Lighter text color */
  margin-top: -0.5rem;
}

.scroll-up-button:hover {
  background-color: #2563eb;
}

/* Additional styling omitted for brevity */
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

* {
  font-family: 'Inter', sans-serif;
  color: white; /* Ensure text color is visible on black background */
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: white; /* Text color for elements in the container */
}

.date-wrapper {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  color: white;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

  .celeb-description {
  margin-top:  -5px;
}

.celeb-name {
  font-weight: bold;
  font-size: 18px !important;
  color: #3b82f6 !important;
}

.tabs button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 8px;
  background-color: #e5e7eb;
  color: black; /* Button text color */
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tabs button.active {
  background-color: #3b82f6;
  color: white;
}

.tabs button:hover {
  background-color: #2563eb;
  color: white;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.fact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.fact-card {
  background-color: #1f1f1f; /* Darker background for event cards */
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Softer white shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  height: 250px;
}

.event-card {
  background-color: #1f1f1f; /* Darker background for event cards */
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Softer white shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 435px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.death-event-card {
  background-color: #1f1f1f; /* Darker background for event cards */
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Softer white shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 425px;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.15);
}

h3 {
  color: #3b82f6;
}


.event-card-link {
  text-decoration: none;
  color: inherit;
}

.death-event-image {
  width: 100%;
  height: auto;
  max-height: 230px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.event-image {
  width: 100%;
  height: auto;
  max-height: 230px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  margin-top: 20px
}

.event-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #3b82f6;
}

.event-card p {
  font-size: 0.875rem;
  color: #d1d5db; /* Lighter text for descriptions */
  line-height: 1.5;
}

.fact-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #3b82f6;
}

.fact-card p {
  font-size: 0.875rem;
  color: #d1d5db; /* Lighter text for descriptions */
  line-height: 1.5;
}

  @media (max-width: 768px) {
    .event-card {
      height: 500px;
    }

    .event-image {
      max-height: 350px
    }

    .death-event-image {
      max-height: 325px;
      height: 325px;
      margin-top: 25px;
    }

    .death-event-card {
      height: 500px;
    }

    .date-wrapper {
      margin-top: 1rem;
    }

    .death-date {
      margin-top: -10px;
      margin-bottom: 10px !important;
    }
  }
</style>
