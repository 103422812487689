<template>
  <div id="app">
    <main>
      <NewsFeed />
    </main>
  </div>
</template>

<script>
import NewsFeed from './components/NewsFeed.vue';

export default {
  components: {
    NewsFeed,
  },
};
</script>

